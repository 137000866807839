import React, { useCallback } from 'react';
import MainBanner from './MainBanner';

const MainPage = ({ msisdn }: { msisdn?: string; }) => {

  const toggleModalSubscribe = useCallback(() => {
    if (msisdn) {
      alert(`Будет отправлен запрос на покупку билета для номера ${msisdn}`);
    } else {
      alert(`Запрос на покупку билета не будет отправлен, так как номер неизвестен`);
    }
  }, [msisdn]);

  return (
    <>
      <MainBanner setOpenModalSubscribe={ toggleModalSubscribe }/>
    </>
  );
};

export default MainPage;
