import React from 'react';
import { useLocation } from 'react-router';

import styled from '@material-ui/core/styles/styled';
import { TCustomTheme } from '../const/theme';
import { Footer } from './footer/Footer';
import Header from './header/Header';
import MainPage from './mainPage';

const AppWrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  display: 'flex',
  minHeight: '100%',
  flexDirection: 'column',
  padding: 0,
  margin: 0,
  color: theme.colors.darkGrey
}));

const Content = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  flex: '1 0 auto'
}));

function App() {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const msisdn = searchQuery?.get('number') as string;

  return (
    <AppWrapper>
      <Header />
      <Content>
        <MainPage msisdn={ msisdn } />
      </Content>
      <Footer />
    </AppWrapper>
  );
}

export default App;
