import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { TCustomTheme } from '../../const/theme';
import Button from '../common/Button';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container/Container';

const BannerBackground = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  backgroundColor: theme.colors.lightestGrey
}));

const BannerBackgroundPart2 = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  backgroundColor: 'white',
  [theme.breakpoints.up('md')]: {
    height: 0,
    backgroundColor: theme.colors.lightestGrey
  }
}));

const BannerWrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  paddingTop: 20,
  paddingBottom: 20,
  [theme.breakpoints.up('md')]: {
    paddingTop: 44,
    paddingBottom: 44,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: '230px',
    height: 500
  }
}));

const BannerHead = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

const ButtonBlock = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 630,
    position: 'relative',
    top: -380
  }
}));

const ButtonBlockText = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    marginBottom: 40,
    display: 'block'
  }
}));

const Strong = styled('strong')(({ theme }) => ({
  color: theme.palette.secondary.main
}));

type Props = {
  setOpenModalSubscribe: () => void;
};

const MainBanner: FC<Props> = ({ setOpenModalSubscribe }) => {
  const { t } = useTranslation();

  return (
    <>
      <BannerBackground>
        <Container>
          <BannerWrapper>
            <BannerHead variant="h1" color="secondary">
              { t('yourLuck') }
            </BannerHead>
          </BannerWrapper>
        </Container>
      </BannerBackground>
      <BannerBackgroundPart2>
        <Container>
          <ButtonBlock>
            <ButtonBlockText variant="h3">
              <Trans
                i18nKey="yourLuckLottery"
                components={ [ <Strong key="1">name</Strong> ] }
              />
              <br />
              <Typography variant={ 'h5' } color={ 'secondary' }>
                { t('withPrizes') }
              </Typography>
            </ButtonBlockText>
            <Button onClick={ setOpenModalSubscribe } fullWidth color="primary" variant="contained" disableElevation >
              { t('participate') }
            </Button>
            <p/>
          </ButtonBlock>
        </Container>
      </BannerBackgroundPart2>
    </>
  );
};

export default MainBanner;
